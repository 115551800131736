import Job from '@/views/settings/master/approval-matrix/Job.vue';
let settingRoutes = [
    // job board
    // Integrations
    {
        path: '/settings/master/integrations',
        name: 'integrations',
        component: () =>
            import(
                /* webpackChunkName: "integrations" */ '@/views/settings/master/integrations/Home.vue'
            )
    },
    {
        path: '/settings/master/integrations/meeting-platforms',
        name: 'integrations-meeting-platforms',
        component: () =>
            import(
                /* webpackChunkName: "integrations-meeting-platform" */ '@/views/settings/master/integrations/MeetingPlatform.vue'
            )
    },
    {
        path: '/settings/master/integrations/api-resource',
        name: 'integrations-api-resource',
        component: () =>
            import(
                /* webpackChunkName: "integrations-api-resource" */ '@/views/settings/master/integrations/ApiResource.vue'
            )
    },
    {
        path: '/settings/master/integrations/offer-letter-sending-endpoint',
        name: 'integrations-offer-letter-sending-endpoint',
        component: () =>
            import(
                /* webpackChunkName: "integrations-offer-letter-sending-endpoint" */ '@/views/settings/master/integrations/OfferLetterSending.vue'
            )
    },
    // offer approval
    {
        path: '/settings/master/approval-matrix',
        name: 'approval-matrix',
        component: () =>
            import(
                /* webpackChunkName: "approval-matrix" */ '@/views/settings/master/approval-matrix/Home.vue'
            )
    },
    {
        path: '/settings/master/approval-matrix/job',
        name: 'approval-matrix-job',
        component: Job
    },
    {
        path: '/settings/master/approval-matrix/offer',
        name: 'approval-matrix-offer',
        component: () =>
            import(
                /* webpackChunkName: "approval-matrix-offer" */ '@/views/settings/master/approval-matrix/Offer.vue'
            )
    },

    // account and user
    {
        path: '/settings/master/account-and-users',
        name: 'account-and-users',
        component: () =>
            import(
                /* webpackChunkName: "account-and-users" */ '@/views/settings/master/account-and-users/Home.vue'
            )
    },
    {
        path: '/settings/master/account-and-users/all',
        name: 'account-and-users-all',
        component: () =>
            import(
                /* webpackChunkName: "account-and-users-all" */ '@/views/settings/master/account-and-users/UserList.vue'
            )
    },

    // permission
    {
        path: '/settings/master/permission',
        name: 'permission',
        component: () =>
            import(
                /* webpackChunkName: "permission" */ '@/views/settings/master/account-and-users/permission/Permission.vue'
            )
    },

    // {
    // 	path: '/settings/master/account-and-users/add',
    // 	name: 'account-and-users-add',
    // 	component: () =>
    // 		import(
    // 			/* webpackChunkName: "account-and-users-add" */ '@/views/settings/master/account-and-users/Add.vue'
    // 		),
    // },

    // master view
    {
        path: '/settings/master/master-view',
        name: 'master-view',
        component: () =>
            import(
                /* webpackChunkName: "master-view" */ '@/views/settings/master/master-view/Overview.vue'
            )
    },

    {
        path: '/settings/master/email',
        name: 'email-master-settings',
        component: () =>
            import(
                /* webpackChunkName: "email-master-settings" */ '@/views/settings/master/email/Home.vue'
            )
    },

    // {
    // 	path: '/settings/master/team-allocation',
    // 	name: 'settings-team-allocation-overview',
    // 	component: () =>
    // 		import('@/views/settings/master/team-allocation/Overview.vue'),
    // },

    // {
    // 	path: '/settings/master/team-allocation/list',
    // 	name: 'settings-team-allocation-list',
    // 	component: () =>
    // 		import('@/views/settings/master/team-allocation/AllocationList.vue'),
    // },

    // carer page routes
    {
        path: '/settings/master/career-page',
        name: 'career-page-master-settings',
        component: () =>
            import(
                /* webpackChunkName: "career-page-master-settings" */ '@/views/settings/master/career-page/Home.vue'
            )
    },
    {
        path: '/settings/master/career-page/domains',
        name: 'career-page-master-settings-domains',
        component: () =>
            import(
                /* webpackChunkName: "career-page-master-settings-domains" */ '@/views/settings/master/career-page/Domains.vue'
            )
    },

    {
        path: '/settings/master/career-page/configuration',
        name: 'career-page-configuration',
        component: () =>
            import(
                '@/views/settings/master/career-page/PortalConfiguration.vue'
            )
    },

    {
        path: '/settings/master/career-page/menu',
        name: 'career-page-menu',
        component: () =>
            import('@/views/settings/master/career-page/PortalMenu.vue')
    },

    {
        path: '/settings/master/career-page/page-link',
        name: 'career-page-page-link',
        component: () =>
            import('@/views/settings/master/career-page/PortalPageLink.vue')
    },

    {
        path: '/settings/master/career-page/job-fields',
        name: 'career-page-job-fields',
        component: () =>
            import('@/views/settings/master/career-page/PortalJobFields.vue')
    },

    {
        path: '/settings/master/career-page/candidate-fields',
        name: 'career-page-candidate-fields',
        component: () =>
            import(
                '@/views/settings/master/career-page/PortalCandidateFields.vue'
            )
    },

    {
        path: '/settings/master/career-page/banners',
        name: 'career-page-banners',
        component: () =>
            import('@/views/settings/master/career-page/PortalBanner.vue')
    },

    {
        path: '/settings/master/resumes',
        name: 'resumes-master-settings',
        component: () =>
            import(
                /* webpackChunkName: "resumes-master-settings" */ '@/views/settings/master/resumes/Home.vue'
            )
    },
    {
        path: '/settings/master/job-board',
        name: 'job-board-master-settings',
        component: () =>
            import(
                /* webpackChunkName: "job-board-master-settings" */ '@/views/settings/master/job-board/Home.vue'
            )
    },
    {
        path: '/settings/master/job-board/configuration',
        name: 'job-board-master-settings-configuration',
        component: () =>
            import(
                /* webpackChunkName: "job-board-master-settings-configuration" */ '@/views/settings/master/job-board/Configuration.vue'
            )
    },
    {
        path: '/settings/master/job-board/general-settings',
        name: 'job-board-master-settings-general-settings',
        component: () =>
            import(
                /* webpackChunkName: "job-board-master-settings-general-settings" */ '@/views/settings/master/job-board/GeneralSettings.vue'
            )
    },
    {
        path: '/settings/master/onboarding/',
        name: 'onboarding-master-settings',
        component: () =>
            import(
                /* webpackChunkName: "onboarding-master-setting" */ '@/views/settings/master/onboarding/Home.vue'
            )
    },
    {
        path: '/settings/master/onboarding/document-requirements',
        name: 'onboarding-master-settings-document-requirements',
        component: () =>
            import(
                /* webpackChunkName: "onboarding-master-setting-document-requirements" */ '@/views/settings/master/onboarding/DocumentRequirements.vue'
            )
    },

    {
        path: '/settings/master/api',
        name: 'api-master-settings',
        component: () =>
            import(
                /* webpackChunkName: "api-master-settings" */ '@/views/settings/master/api/Home.vue'
            )
    },
    {
        path: '/settings/master/ex-employee',
        name: 'master-settings-ex-employee',
        component: () =>
            import(
                /* webpackChunkName: "master-settings-ex-employee" */ '@/views/settings/master/ex-employee/List.vue'
            )
    }
];
settingRoutes = [...settingRoutes].map((route) => ({
    ...route,
    meta: { ...route.meta, role: ['Admin'] }
}));
export default settingRoutes;
