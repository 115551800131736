const appRoutes = [
	{
		path: '/calendar',
		name: 'calendar',
		component: () =>
			import(
				/* webpackChunkName: "calendar" */ '@/views/calendar/Calendar.vue'
			),
	},

	// mailbox routes
	{
		path: '/mailbox',
		name: 'mailbox',
		component: () => import('@/views/mailbox/Home.vue'),
	},

	// mailbox outlook
	{
		path: '/mailbox/outlook',
		name: 'mailbox-outlook',
		component: () => import('@/views/mailbox/outlook/Home.vue'),
	},

	{
		path: '/mailbox/auth-redirect/microsoft',
		name: 'msal-auth-callback',
		component: () => import('@/external-auth/MsalAuthRedirect.vue'),
	},

	// mailbox gmail
	// {
	// 	path: '/mailbox/gmail',
	// 	name: 'mailbox-gmail',
	// 	component: () => import('@/views/mailbox/gmail/Home.vue'),
	// },

	// visa quota
	{
		path: '/visa-quota/companies',
		name: 'visa-quota-companies',
		component: () =>
			import(
				'@/views/settings/master/master-view/visa-quota/QuotaCompanies.vue'
			),
	},

	{
		path: '/visa-quota/templates',
		name: 'visa-quota-templates',
		component: () =>
			import('@/views/settings/master/master-view/visa-quota/Templates.vue'),
	},

	// profile
	{
		path: '/user/profile',
		name: 'user-profile',
		component: () => import('@/views/profile/menu-profile/Profile.vue'),
	},

	{
		path: '/user/email-signatures',
		name: 'user-email-signatures',
		component: () => import('@/views/profile/menu-profile/EmailSignatures.vue'),
	},
];

export default appRoutes;
