<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import useAuth from '@/composables/auth/useAuth';
import useSettings from '@/composables/app/useSettings';
import useCommonUtils from '@/composables/app/useCommonUtils';
import { name } from '@azure/msal-browser/dist/packageMetadata';

const store = useStore();
const { authUser, hasAccess } = useAuth();
const menu_collapse = ref('dashboard');
const { teamSettings } = useSettings();
const { textCapitalize } = useCommonUtils();

const isAdminLoggedIn = computed(() => {
  return store.state.auth.currentRole === 'Admin';
});

onMounted(async () => {
  const selector = document.querySelector(
    '#sidebar a[href="' + window.location.pathname + '"]'
  );
  if (selector) {
    const ul = selector.closest('ul.collapse');
    if (ul) {
      let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
      if (ele) {
        ele = ele[0];
        setTimeout(() => {
          ele.click();
        });
      }
    } else {
      selector.click();
    }
  }
});

const toggleMobileMenu = () => {
  if (window.innerWidth < 991) {
    store.commit('toggleSideBar', !store.state.is_show_sidebar);
  }
};

const menuNames = computed(() => teamSettings.value?.sidebarMenuNames || {});
const resolveMenuName = (key = '') => {
  if (!key in menuNames.value) return key.split('_').join(' ');
  return menuNames.value[key]?.name
    ? menuNames.value[key]?.name
    : menuNames.value[key]?.default_name
    ? menuNames.value[key]?.default_name
    : '';
};
</script>

<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav
      ref="menu"
      id="sidebar"
    >
      <div class="shadow-bottom"></div>

      <perfect-scrollbar
        class="list-unstyled menu-categories"
        tag="ul"
        :options="{
          wheelSpeed: 0.5,
          swipeEasing: !0,
          minScrollbarLength: 40,
          maxScrollbarLength: 300,
          suppressScrollX: true
        }"
      >
        <!-- dashboard -->
        <li class="menu">
          <router-link
            to="/"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="d-flex align-items-center">
              <FeatherIcon
                type="home"
                size="24"
                stroke-width="2"
                class="feather"
              />
              <span>{{ resolveMenuName('dashboard') }}</span>
            </div>
          </router-link>
        </li>

        <!-- recruitments -->
        <li class="menu">
          <a
            class="dropdown-toggle"
            data-bs-toggle="collapse"
            data-bs-target="#recruitments"
            aria-controls="recruitments"
            aria-expanded="false"
          >
            <div class="d-flex flex-row align-items-center">
              <FeatherIcon
                type="codesandbox"
                size="24"
                stroke-width="2"
                class="feather"
              />
              <span>{{ resolveMenuName('recruitments') }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul
            id="recruitments"
            class="collapse submenu list-unstyled"
            data-bs-parent="#sidebar"
          >
            <li v-if="hasAccess({ name: 'org', option: 'view' })">
              <router-link
                :to="{ name: 'recruitments-organizations' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('organizations') }}
              </router-link>
            </li>
            <li v-if="hasAccess({ name: 'company', option: 'view' })">
              <router-link
                :to="{ name: 'recruitments-companies' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('companies') }}
              </router-link>
            </li>
            <li
              v-if="
                hasAccess({
                  name: 'business_unit',
                  option: 'view'
                })
              "
            >
              <router-link
                :to="{ name: 'recruitments-business-units' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('business_units') }}
              </router-link>
            </li>
            <li
              v-if="
                hasAccess({
                  name: 'department',
                  option: 'view'
                })
              "
            >
              <router-link
                :to="{ name: 'recruitments-departments' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('departments') }}
              </router-link>
            </li>
            <li v-if="hasAccess({ name: 'job', option: 'view' })">
              <router-link
                :to="{ name: 'recruitments-jobs' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('jobs') }}
              </router-link>
            </li>
            <li v-if="hasAccess({ name: 'candidate', option: 'view' })">
              <router-link
                :to="{ name: 'recruitments-candidates' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('candidates') }}
              </router-link>
            </li>
            <li v-if="hasAccess({ name: 'applicant', option: 'view' })">
              <router-link
                :to="{ name: 'recruitments-applicants' }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('applicants') }}
              </router-link>
            </li>
            <li
              v-if="
                hasAccess({
                  name: 'onboarding',
                  option: 'view'
                })
              "
            >
              <router-link
                :to="{
                  name: 'recruitments-onboarding-documents'
                }"
                @click="toggleMobileMenu"
              >
                {{ resolveMenuName('onboarding') }}
              </router-link>
            </li>
          </ul>
        </li>

        <!-- resume search -->
        <li
          class="menu"
          v-if="hasAccess({ name: 'resume_search', option: 'view' })"
        >
          <router-link
            to="/resume-search"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="d-flex align-items-center">
              <FeatherIcon
                type="search"
                size="24"
                stroke-width="2"
                class="feather"
              />
              <span>{{ resolveMenuName('resume_search') }}</span>
            </div>
          </router-link>
        </li>

        <!-- calendar -->
        <li class="menu">
          <router-link
            to="/calendar"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                data-v-02c2cbc4=""
                class="feather feather-calendar"
              >
                <rect
                  x="3"
                  y="4"
                  width="18"
                  height="18"
                  rx="2"
                  ry="2"
                ></rect>
                <line
                  x1="16"
                  y1="2"
                  x2="16"
                  y2="6"
                ></line>
                <line
                  x1="8"
                  y1="2"
                  x2="8"
                  y2="6"
                ></line>
                <line
                  x1="3"
                  y1="10"
                  x2="21"
                  y2="10"
                ></line>
              </svg>
              <span>{{ resolveMenuName('calendar') }}</span>
            </div>
          </router-link>
        </li>

        <!-- email -->
        <li class="menu">
          <router-link
            :to="{ name: 'mailbox' }"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                data-v-02c2cbc4=""
                class="feather feather-mail"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <span>{{ resolveMenuName('email') }}</span>
            </div>
          </router-link>
        </li>

        <!-- master settings -->
        <li
          class="menu"
          v-if="isAdminLoggedIn"
        >
          <a
            class="dropdown-toggle"
            data-bs-toggle="collapse"
            data-bs-target="#master_settings"
            aria-controls="master_settings"
            aria-expanded="false"
          >
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                data-v-02c2cbc4=""
                class="feather feather-settings"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="3"
                ></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                ></path>
              </svg>
              <span>{{ resolveMenuName('master_settings') }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul
            id="master_settings"
            class="collapse submenu list-unstyled"
            data-bs-parent="#sidebar"
          >
            <li>
              <router-link
                to="/settings/master/account-and-users"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('account_and_users') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/master-view"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('master_view') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/email"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('email') }}</router-link
              >
            </li>

            <!-- <li>
							<router-link
								:to="{ name: 'settings-team-allocation-overview' }"
								@click="toggleMobileMenu"
								>Team Allocation</router-link
							>
						</li> -->

            <!-- <li>
							<router-link
								to="/settings/master/resumes"
								@click="toggleMobileMenu"
								>Resume</router-link
							>
						</li> -->
            <li>
              <router-link
                to="/settings/master/career-page"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('career_page') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/job-board"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('job_board') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/approval-matrix"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('approval_matrix') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/onboarding"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('onboarding') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/integrations"
                @click="toggleMobileMenu"
                >{{ resolveMenuName('integrations') }}</router-link
              >
            </li>
            <li>
              <router-link
                to="/settings/master/ex-employee"
                @click="toggleMobileMenu"
                >Ex-Employee</router-link
              >
            </li>
          </ul>
        </li>

        <!-- visa quota -->
        <li class="menu">
          <router-link
            :to="{ name: 'visa-quota-companies' }"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
              >
                <path
                  d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"
                ></path>
                <polyline points="8 10 12 14 16 10"></polyline>
              </svg>
              <span>{{ resolveMenuName('visa_quota') }}</span>
            </div>
          </router-link>
        </li>

        <!-- Reports -->
        <li class="menu">
          <router-link
            :to="{ name: 'reports' }"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
              >
                <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
              </svg>
              <span>{{ resolveMenuName('reports') }}</span>
            </div>
          </router-link>
        </li>

        <!-- feedback -->
        <li class="menu">
          <router-link
            to="/feedback"
            class="dropdown-toggle"
            @click="toggleMobileMenu"
          >
            <div class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                data-v-02c2cbc4=""
                class="feather feather-message-circle"
              >
                <path
                  d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"
                ></path>
              </svg>
              <span>{{ resolveMenuName('feedback') }}</span>
            </div>
          </router-link>
        </li>
      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<style scoped>
.feather-chevron-right {
  transition: all 0.2s ease-out;
}
</style>
